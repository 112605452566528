import React, { useContext, useState } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import './Header.css';
import SmallLogo from '../../images/pixel-small-logo.png';
import DAOToken from '../../images/DAO_token.png';
import MarketBtn from '../../images/orange btn.png';
import MarketBtnHover from '../../images/orange btn hover.png';
import IroneIcon from '../../images/iron_icon.png';
import WoodIcon from '../../images/wood_icon.png';
import UpgradeHouse from '../../images/upgrade_icon.png';
import UpgradeHouseActive from '../../images/upgrade_btn active_hover.png';
import { claimMiningRes, upgradeFarmingItems } from '../../services/wax.service';

export default function Header({
                    title,
                    setTitle,
                    upgradeIcon,
                    miningCount,
                    selectedHouse,
                    isModalOpen,
                    setIsModalOpen,
                    handleSwapModal,
                    resources,
                }) {
    const { activeUser } = useContext(UALContext);
    const [upgradeHouseEnter, setUpgradeHouseEnter] = useState(false);
    const [activeBtnMarket, setActiveBtnMarket] = useState(false);

    const handleClaim = () => {
        claimMiningRes({ activeUser, farmingItemID: selectedHouse });
    };

    const handleUpgradeFarmItem = () => {
        upgradeFarmingItems({ activeUser, farmingItemID: selectedHouse });
    };

    const renderResourceImg = (resource) => {
        if (resource?.resource_name === 'stone') {
            return <img src={IroneIcon} alt="" />;
        }
        if (resource?.resource_name === 'wood') {
            return <img src={WoodIcon} alt="" />;
        }
        return null;
    };

    const renderResources = (resources) => {
        return resources?.map((resource, index) => (
            <div className="item" key={index}>
                {renderResourceImg(resource)}
                {resource ? (
                    <p>{Number(resource.amount).toFixed(3)}</p>
                ) : (
                    <p>000</p>
                )}
            </div>
        ));
    };

    return (
        <div className="header">
            <div className="resourses-list">{renderResources(resources)}</div>

            {title ? (
                <>
                    <h2 className="header-title">{title}</h2>
                    {title === 'MINING' && (
                        <p className="header-subtitle">
                            mining:{' '}
                            {miningCount ? (
                                <span onClick={handleClaim} style={{ cursor: 'pointer' }}>
                  {miningCount}
                </span>
                            ) : (
                                <span>0</span>
                            )}
                        </p>
                    )}
                    {upgradeIcon && (
                        <div
                            className="header-upgrade-house"
                            onClick={handleUpgradeFarmItem}
                            onMouseEnter={() => setUpgradeHouseEnter(true)}
                            onMouseLeave={() => setUpgradeHouseEnter(false)}
                        >
                            <img
                                className="header-upgrade-house_disabled"
                                src={UpgradeHouse}
                                alt=""
                            />
                            {upgradeHouseEnter && (
                                <>
                                    <img
                                        className="header-upgrade-house_active"
                                        src={UpgradeHouseActive}
                                        alt=""
                                    />
                                    <p className="header-upgrade-house_active-price">00 000</p>
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <img className="logo-map" src={SmallLogo} alt="" />
            )}

            <div className="header-right">
                <div className="user-dao">
                    <img src={DAOToken} alt="DAO Token" />
                    <p>000 000</p>
                </div>
                <div
                    className="market-btn"
                    onMouseEnter={() => setActiveBtnMarket(true)}
                    onMouseLeave={() => setActiveBtnMarket(false)}
                    onClick={handleSwapModal}
                >
                    <img
                        src={activeBtnMarket ? MarketBtnHover : MarketBtn}
                        alt="Market Button"
                    />
                    <span>SWAP</span>
                </div>
            </div>

        </div>
    )
}