import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TraitBanner from "../../images/trait_banner.png";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: '#ffffff',
        maxWidth: 289,
        fontSize: theme.typography.pxToRem(14),
        fontFamily: 'GillSansC, sans-serif!important',
        fontWeight: 'bold',
        fontStyle: 'normal',
    },
}));

export default function ToolTip() {
    return (
        <div>

            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography>Info about trait</Typography>
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                    </React.Fragment>
                }
            >
                <img className={'tailt-banner'} src={TraitBanner} alt=""/>
            </HtmlTooltip>
        </div>
    );
}