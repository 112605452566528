import React, { useState, useContext, useEffect } from "react";
import BuildingHovers from "./BuildingHovers";
import Header from "./Header/Header";
import SwapTokenModal from "./modals/SwapTokenModal";
import { getResources } from "../services/wax.service";
import { UALContext } from "ual-reactjs-renderer";

export default function MapImageMapper(props) {
    const { openMiningModal, openUpgradeModal, openBlandingModal, clickBlandHouse, clickMiningHouse, clickUpgradeHouse, clickStakingHouse } = props;
    const { activeUser } = useContext(UALContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [resources, setResources] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getResources({ activeUser });
                setResources(data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [activeUser]);

    function handleSwapModal(isModalOpen) {
        setIsModalOpen(isModalOpen);
    }

    return (
        <>
            <Header
                upgradeIcon={false}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleSwapModal={handleSwapModal}
                resources={resources}
            />
            <BuildingHovers
                openMiningModal={openMiningModal}
                openUpdateModal={openUpgradeModal}
                openBlandingModal={openBlandingModal}
                clickBlandHouse={clickBlandHouse}
                clickMiningHouse={clickMiningHouse}
                clickUpgradeHouse={clickUpgradeHouse}
                clickStakingHouse={clickStakingHouse}
            />
            <SwapTokenModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} resources={resources} />
        </>
    );
}
