import React, {useState, useContext} from 'react'

import CloseIcon from '../../images/close_btn.png'
import GreenBtn from '../../images/green_btn.png'
import GreenBtnHover from '../../images/green_btn_hover.png'
import UnactiveBtn from '../../images/unactive_btn.png'
import {stakeFarmingItem, unStakeFarmingItem} from "../../services/wax.service";
import {UALContext} from "ual-reactjs-renderer";
import {Link} from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";

import './StakingModal.css'
import Header from "../Header/Header";





export default function StakingModal({close, items, stakedItems}) {
    const { activeUser } = useContext(UALContext);
    const [stakingMenuSelected, setStakingMenuSelected] = useState(0)
    const [stakingItemsSelected, setStakingItemSelected] = useState([])
    const [unstakingItemsSelected, setUnStakingItemSelected] = useState([])
    const [selectedItemForStaking, setSelectedItemForStaking] = useState([])
    const [selectedItemForUnStaking, setSelectedItemForUnStaking] = useState([])
    const [hoverBtnStaking, setHoverBtnStaking] = useState(false);
    const [hoverBtnUnstaking, setHoverBtnUnstaking] = useState(false);


    const toggleActiveItemForStake = (item) => {

        if(stakingItemsSelected.includes(item)){
            setStakingItemSelected(stakingItemsSelected.filter((i) => i !== item))
            setSelectedItemForStaking(selectedItemForStaking.filter((i) => i !== item.asset_id))
        } else {
            setStakingItemSelected([...stakingItemsSelected, item])
            setSelectedItemForStaking([...selectedItemForStaking, item.asset_id])
        }

    }

    const getSlotsContentForStake = item => {
        let content = [];
        for (let i = 0; i < 20; i++) {
            content.push(
                <div className="staking-info-block" onClick={item[i] ? () => toggleActiveItemForStake(item[i]) : null}>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-staking-info_name'}>{item[i]?.data.name}</p>
                    <div className={!selectedItemForStaking.includes(item[i]?.asset_id) ? "staking-item staking-item_unactive" : "staking-item staking-item_selected"}>
                        {item[i] && <img src={`https://atomichub-ipfs.com/ipfs/${item[i]?.data.img}`} alt=""/>}
                    </div>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-staking-info'}>/level 1/</p>
                    <p className={'item-staking-info'}>{item[i]?.asset_id}</p>
                </div>
            );
        }
        return content;
    };

    const getSlotsContentForUnStake = item => {
        let content = [];
        for (let i = 0; i < 20; i++) {
            content.push(
                <div className="staking-info-block" onClick={item[i] ? () => toggleActiveItemForUnStake(item[i]) : null}>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-staking-info_name'}>{item[i]?.data.name}</p>
                    <div className={!selectedItemForUnStaking.includes(item[i]?.asset_id) ? "staking-item staking-item_unactive" : "staking-item staking-item_selected"}>
                        {item[i] && <img src={`https://atomichub-ipfs.com/ipfs/${item[i]?.data.img}`} alt=""/>}
                    </div>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-staking-info'}>/level 1/</p>
                    <p className={'item-staking-info'}>{item[i]?.asset_id}</p>
                </div>
            );
        }
        return content;
    };

    const toggleActiveItemForUnStake = (item) => {

        if(unstakingItemsSelected.includes(item)){
            setUnStakingItemSelected(unstakingItemsSelected.filter((i) => i !== item))
            setSelectedItemForUnStaking(selectedItemForUnStaking.filter((i) => i !== item.asset_id))
        } else {
            setUnStakingItemSelected([...unstakingItemsSelected, item])
            setSelectedItemForUnStaking([...selectedItemForUnStaking, item.asset_id])
        }

    }


    const handleStake = () => {
        stakeFarmingItem({activeUser, nftIDs: selectedItemForStaking})
    }

    const handleUnstake = () => {
        unStakeFarmingItem({activeUser, nftIDs: selectedItemForUnStaking})
    }

    return (
        <>
            <Header title={'STAKING'}  upgradeIcon={false} />
                <>
                    {stakingMenuSelected === 0 &&
                        <div className={'staking-btn'}
                             onMouseEnter={() => setHoverBtnStaking(true)}
                             onMouseLeave={() => setHoverBtnStaking(false)}
                             onClick={() => handleStake()}
                        >
                            { stakingItemsSelected.length ?
                                hoverBtnStaking ?
                                    <img src={GreenBtnHover} alt=""/>
                                    :
                                    <img src={GreenBtn} alt=""/>

                                :
                                <img src={UnactiveBtn} alt=""/>
                            }

                            <span>STAKE</span>
                        </div>
                    }

                    {stakingMenuSelected === 1 &&
                        <div className={'staking-btn'}
                             onMouseEnter={() => setHoverBtnUnstaking(true)}
                             onMouseLeave={() => setHoverBtnUnstaking(false)}
                             onClick={() => handleUnstake()}
                        >
                            { unstakingItemsSelected.length ?
                                hoverBtnUnstaking ?
                                    <img src={GreenBtnHover} alt=""/>
                                    :
                                    <img src={GreenBtn} alt=""/>

                                :
                                <img src={UnactiveBtn} alt=""/>
                            }

                            <span>UNSTAKE</span>
                        </div>
                    }
                    <Link to={'/'}>
                        <div className="close" onClick={close}>
                            <img src={CloseIcon} alt=""/>
                        </div>
                    </Link>
                </>


            <div className={"staking-modal"}>

                <div className={"staking-modal-window"}>
                    {stakingMenuSelected === 0 &&
                                <>
                                    <div className="sort-block">

                                    </div>
                                    <div className={"items-list"}>
                                        {/*{items.map(item =>*/}
                                        {/*    <div className="staking-info-block" onClick={() => toggleActiveItemForStake(item)}>*/}
                                        {/*        <p className={'item-staking-info_name'}>{item.data.name}</p>*/}
                                        {/*        <div className={!selectedItemForStaking.includes(item.asset_id) ? "staking-item staking-item_unactive" : "staking-item staking-item_selected"}>*/}
                                        {/*            <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>*/}
                                        {/*        </div>*/}
                                        {/*        <p className={'item-staking-info'}>/level 1/</p>*/}
                                        {/*        <p className={'item-staking-info'}>{item.asset_id}</p>*/}
                                        {/*    </div>*/}
                                        {/*)}*/}

                                        {getSlotsContentForStake(items)}
                                    </div>
                                    <SearchBar/>
                                </>
                        }

                    {stakingMenuSelected === 1 &&
                        <>
                            <div className="sort-block">

                            </div>
                            <div className={"items-list"}>
                                {/*{stakedItems.map(item =>*/}
                                {/*    <div className="staking-info-block" onClick={() => toggleActiveItemForUnStake(item)}>*/}
                                {/*        <p className={'item-staking-info_name'}>{item.data.name}</p>*/}
                                {/*        <div className={!selectedItemForUnStaking.includes(item.asset_id) ? "staking-item staking-item_unactive" : "staking-item staking-item_selected"}>*/}
                                {/*            <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>*/}
                                {/*        </div>*/}
                                {/*        <p className={'item-staking-info'}>/level 1/</p>*/}
                                {/*        <p className={'item-staking-info'}>{item.asset_id}</p>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {getSlotsContentForUnStake(stakedItems)}
                            </div>
                            <SearchBar/>
                        </>
                    }


                </div>
                    <div className="staking-menu">
                        <div className={stakingMenuSelected === 0 ? "staking-menu_block_active" : "staking-menu_block"} onClick={() => setStakingMenuSelected(0)}>
                            <p>WAX WALLET</p>
                        </div>
                        <div className={stakingMenuSelected === 1 ? "staking-menu_block_active" : "staking-menu_block"} onClick={() => setStakingMenuSelected(1)}>
                            <p>STAKED<br/> TO PIXEL DAO</p>
                        </div>
                    </div>
            </div>
        </>

    )
}