export const MAP_IMAGE_MAP_WIDTH_INITIAL = '1900';
export const MAP_IMAGE_MAP_HEIGHT_INITIAL = '1080';

export const MAP_IMAGE_MAP_WIDTH_2 = '1800';
export const MAP_IMAGE_MAP_HEIGHT_2 = '1012.5';

export const MAP_IMAGE_MAP_WIDTH_3 = '1600';
export const MAP_IMAGE_MAP_HEIGHT_3 = '900';

export const MAP_IMAGE_MAP_WIDTH_4 = '1400';
export const MAP_IMAGE_MAP_HEIGHT_4 = '787.5';

export const MAP_IMAGE_MAP_WIDTH_5 = '1200';
export const MAP_IMAGE_MAP_HEIGHT_5 = '675';

export const MAP_IMAGE_MAP_WIDTH_6 = '1000';
export const MAP_IMAGE_MAP_HEIGHT_6 = '529.4';

export const MAP_IMAGE_MAP_WIDTH_7 = '800';
export const MAP_IMAGE_MAP_HEIGHT_7 = '450';

export const MAX_MAPS_QUANTITY = 4000;