import React, { useEffect, useState, useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { Link } from 'react-router-dom';

import StoneIcon from '../../images/stone_icon.png';
import IroneIcon from '../../images/iron_icon.png';
import WoodIcon from '../../images/wood_icon.png';
import FoodIcon from '../../images/food_icon.png';
import CloseIcon from '../../images/close_btn.png';

import './MiningModal.css';

import SearchBar from '../SearchBar/SearchBar';
import { stakeItems } from '../../services/wax.service';

import Header from '../Header/Header';

export default function MiningModal({ isShow, close, items, farmingItems, stakedItems }) {
    const { activeUser } = useContext(UALContext);
    const [selectedHouse, setSelectedHouse] = useState(stakedItems[0]?.asset_id);
    const [itemsMining, setItemsMining] = useState([]);
    const [miningCount, setMiningCount] = useState(0);

    useEffect(() => {
        const current_time = Date.now() / 1000;
        const count_sum = itemsMining.map((item) => {
            const last_claim = item?.mutable_data.lastClaim;
            const mining_rate = item.data?.miningRate;
            const reward = ((current_time - last_claim) * mining_rate).toFixed(4);
            return reward;
        });

        const result = count_sum.reduce((sum, elem) => +sum + +elem, 0);
        setMiningCount(result);
    }, [itemsMining]);

    const handleMiningItem = (assetId, selectedHouse) => {
        stakeItems({ activeUser, nftID: assetId, farmingItemID: selectedHouse });
    };


    // useEffect(() => {
    //     setSelectedHouse(stakedItems[0]?.asset_id)
    // }, [stakedItems])

    // useEffect(() => {
    //     getMiningItems({activeUser, houseIdSelected: selectedHouse})
    //         .then((items) => {
    //             setItemsMining(items)
    //         })
    //         .catch((e) => {
    //             console.log(e)
    //         })
    // }, [activeUser, selectedHouse])


    const getSlotsContentForInventory = (item, selectedHouse) => {
        let content = [];
        let filteredStakedItems = stakedItems.filter(item => {
            return item?.asset_id === selectedHouse
        })
        let houseSelected = filteredStakedItems[0]

        let stakeableFarmingItemResources = houseSelected?.data.stakeableResources


        for (let i = 0; i < 20; i++) {
            content.push(
                <div className="mining-info-block">
                    <p className={'item-mining-info_name'}>{item[i]?.name}</p>
                    <div className="mining-item mining-item_unactive inventory-item" onClick={() => handleMiningItem(item[i]?.asset_id, selectedHouse)}>
                        {item[i] && <img src={`https://atomichub-ipfs.com/ipfs/${item[i]?.data.img}`} alt=""/>}
                    </div>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>/level 1/</p>
                    <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>/ID {item[i]?.asset_id}/</p>
                </div>
            );
        }
        return content;
    };

    const getSlotsContentForMining = (item, selectedHouse) => {
        console.log(stakedItems)

        let filteredStakedFarmItems = stakedItems.filter((item) => {
            return item.asset_id === selectedHouse
        })

        let content = [];

            for (let i = 0; i < filteredStakedFarmItems[0]?.data.maxSlots; i++) {
                let unactive_slots = filteredStakedFarmItems[0]?.data.maxSlots - filteredStakedFarmItems[0]?.data.slots
                let active_slots = filteredStakedFarmItems[0]?.data.maxSlots - unactive_slots


                content.push(
                    <div className={'mining-item-block'}>
                        {item[i]?.data.img ?
                        <div className={"mining-item"}>
                            {item[i] && <img src={`https://atomichub-ipfs.com/ipfs/${item[i].data.img}`} alt=""/>}
                        </div>
                        :
                        <div className={"mining-item mining-item_unactive inventory-item"}></div>
                        }
                        <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>level {item[i]?.data.level}</p>
                        <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>/ID {item[i]?.asset_id}/</p>
                    </div>
                );
            }

        return content;
    };

    return (
        <>
            <Header title={'MINING'}  upgradeIcon={true} miningCount={miningCount} selectedHouse={selectedHouse}/>
                <Link to={'/'}>
                    <div className="close-mining" onClick={close}>
                        <img src={CloseIcon} alt=""/>
                    </div>
                </Link>

            <div className={"maining-modal"}>
                <div className="maining-modal_mining-stats">
                    <h5>TOTAL MINING:</h5>

                    <div className="stat">
                        <p>number/hour</p>
                        <div className="resourse-item">
                            <img src={StoneIcon} alt=""/>
                        </div>
                    </div>


                    <div className="stat">
                        <p>number/hour</p>
                        <div className="resourse-item">
                            <img src={IroneIcon} alt=""/>
                        </div>
                    </div>

                    <div className="stat">
                        <p>number/hour</p>
                        <div className="resourse-item">
                            <img src={WoodIcon} alt=""/>
                        </div>
                    </div>

                    <div className="stat">
                        <p>number/hour</p>
                        <div className="resourse-item">
                            <img src={FoodIcon} alt=""/>
                        </div>
                    </div>

                </div>

                <div className="maining-modal_main-window">
                    <div className="maining-modal_window_top">
                        <div className="mining-items_list">
                            {getSlotsContentForMining(itemsMining, selectedHouse)}
                        </div>
                    </div>
                    <div className="maining-modal_window_bottom">
                        <div className="maining-modal_window_bottom-title">
                            <h3>INVENTORY</h3>
                        </div>
                        <div className={"available-items"}>

                            {/*<div className="mining-info-block">*/}
                            {/*    <p className={'item-mining-info_name'}>PICK</p>*/}
                            {/*    <div className="mining-item mining-item_unactive inventory-item">*/}
                            {/*        <img src={PickDefoult} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <p className={'item-mining-info'}>/level 1/</p>*/}
                            {/*    <p className={'item-mining-info'}>/ID 1234566/</p>*/}
                            {/*</div>*/}
                            {/*<div className="mining-info-block">*/}
                            {/*    <p className={'item-mining-info_name'}>PICKAXE</p>*/}
                            {/*    <div className="mining-item mining-item_unactive inventory-item">*/}
                            {/*        <img src={PickAxe} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <p className={'item-mining-info'}>/level 1/</p>*/}
                            {/*    <p className={'item-mining-info'}>/ID 1234566/</p>*/}
                            {/*</div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {/*<div className="mining-item mining-item_unactive inventory-item"></div>*/}
                            {getSlotsContentForInventory(items, selectedHouse)}
                        </div>

                        <SearchBar/>
                    </div>
                </div>
                <div className="house-list">
                    {stakedItems ?
                        stakedItems.map(item =>
                            <div className={selectedHouse === item.asset_id ? "house-item_active" : "house-item"} onClick={() => setSelectedHouse(item.asset_id)}>
                                <h4>{item.data.name}</h4>
                                <p>ID {item.asset_id}</p>

                                {selectedHouse === item.asset_id &&
                                    <>
                                        <p className={'faction'}><span>Faction:</span> /{item.data.faction}/</p>
                                        <div style={{display: 'flex'}}>
                                            <p className={'rare'}><span>Rarity:</span> {item.data.rarity}</p>
                                            <p className={'rare'}><span>Mining boost: </span>1x</p>
                                        </div>
                                    </>
                                }
                            </div>
                        )
                        :
                        <></>
                    }
                </div>
            </div>
        </>
    )
}