import axios from "axios";
import {PIXEL_FARMING_CONTRACT} from "../constants";
import {fetchRows, getTableData} from "../helpers/waxChain.helper";


export const getMiningConfig = async () => {
    const { rows } = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: PIXEL_FARMING_CONTRACT,
        table: 'miningconfig',
        limit: 100,
    });

   // TODO
    return rows
};

export const getBlendVotes = async () => {
    const { rows } = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: PIXEL_FARMING_CONTRACT,
        table: 'blendsvotes',
        limit: 100,
    });

    console.log(rows)

    return rows
};

export const getStakedItems = async ({activeUser}) => {
    const { rows } = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: activeUser?.accountName,
        table: 'staked',
        limit: 100,
    });

    let farmingItemsStaked = rows.map(item => {
         return item.asset_id
    })

    let assets = []

    for (let i = 0; i < farmingItemsStaked.length; i++) {
        let { data: data } = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/assets/${farmingItemsStaked[i]}`)
        assets.push(data.data)
    }

    return assets
};

export const getMiningItems = async ({activeUser, houseIdSelected}) => {
    const { rows } = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: activeUser?.accountName,
        table: 'staked',
        limit: 100,
    });

    let itemsMining = rows.filter(item => {
        return item.asset_id === houseIdSelected
    })

    let items = itemsMining.map( item => {
        return item.staked_items
    })

    let a = items.flat()

        let assets = []
        for (let i = 0; i < a.length; i++) {
            let elememt = a[i]
                let { data: data } = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/assets/${elememt}`)
                assets.push(data.data)
        }

        return assets

};

export const getStaked = async ({activeUser}) => {
    const {rows} = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: activeUser?.accountName,
        table: 'staked',
        limit: 100,
    });

    return rows
};


export const getWhiteListIPFS = async () => {
    const data = await getTableData({
        contract: PIXEL_FARMING_CONTRACT,
        scope: 'pixelfarming',
        table: 'wipfs',
        limit: 100,
    });

    // TODO
    return data
};

export const getBlends = async () => {
    const data = await getTableData({
        contract: PIXEL_FARMING_CONTRACT,
        scope: PIXEL_FARMING_CONTRACT,
        table: 'blends',
        limit: 100,
    });
    let resArr = []
    data.map(async (item) => {
        let components = item.blend_components // [12331, 123123]
        let resultItem = item.resulting_item // 3213123


        const {data} = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates/pixelfarming/${resultItem}`)
        let arr = []
        components.map(async (temp) => {
            const comp = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates/pixelfarming/${temp}`)
            arr.push(comp.data.data)
        })

        resArr.push({
            blend_components: arr,
            blend_id: item.blend_id,
            resulting_item: data.data
        })
    })

    return resArr
};


export const getReqIPFS = async () => {
    const data = await getTableData({
        contract: PIXEL_FARMING_CONTRACT,
        scope: 'pixelfarming',
        table: 'req2wipfs',
        limit: 100,
    });

    // TODO
    return data
};

export const getResources = async ({activeUser}) => {
    const data = await getTableData({
        contract: PIXEL_FARMING_CONTRACT,
        scope: activeUser?.accountName,
        table: 'resources',
        limit: 100,
    });

    // TODO
    return data
};


export const getPrices = async () => {
    const { rows } = await fetchRows({
        contract: PIXEL_FARMING_CONTRACT,
        scope: PIXEL_FARMING_CONTRACT,
        table: 'prices',
        limit: 100,
    });

    return rows.find(({key}) => key === 1)
};

export const stakeFarmingItem = async ({ activeUser, nftIDs }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: activeUser?.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    from: activeUser.accountName,
                    to: PIXEL_FARMING_CONTRACT,
                    asset_ids: nftIDs,
                    memo: "stake farming item"
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const swapToDao = async ({ activeUser, resource, amount2swap }) => {
    console.log(activeUser, resource, amount2swap)
    await activeUser.signTransaction({
        actions: [
            {
                account: PIXEL_FARMING_CONTRACT,
                name: "swap",
                authorization: [
                    {
                        actor: activeUser?.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    resource: resource,
                    amount2swap: amount2swap,
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const unStakeFarmingItem = async ({ activeUser, nftIDs }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: "pixelfarming",
                name: "unstfarmitem",
                authorization: [
                    {
                        actor: activeUser?.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    asset_id: nftIDs[0],
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const getFarmingItemSlots = async () => {
    // TODO for testnet
    const { data } = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/assets/1099542635875`)
    console.log(data.data?.mutable_data.slots)
    return data.data?.mutable_data.slots
}

export const getItems = async (owner) => {
    const { data } = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=pixelfarming&owner=${owner?.accountName}`)
    return data.data
}

export const getCollectionItems = async () => {
    const { data } = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=pixelfarming&schema_name=item1&min_issued_supply=1&page=1&limit=100`)
    return data.data
}

export const getCollectionItemsByTemplateId = (temp) => {
    const { data } = axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates/pixelfarming/${temp}`)
    return data?.immutable_data
}




export const stakeItems = async ({ activeUser, nftID, farmingItemID }) => {
    // console.log(activeUser)
    // console.log(nftID)
    // console.log(farmingItemID)
    await activeUser.signTransaction({
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    from: activeUser.accountName,
                    to: PIXEL_FARMING_CONTRACT,
                    asset_ids: [nftID],
                    memo: `stake items:${farmingItemID}`
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const claimMiningRes = async ({ activeUser, farmingItemID }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: PIXEL_FARMING_CONTRACT,
                name: "claim",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    farmingitem: +farmingItemID,
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const createBlendV = async ({ activeUser, ipfs, name, components }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: PIXEL_FARMING_CONTRACT,
                name: "createblendv",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    ipfs: ipfs,
                    item_name: name,
                    blend_components: components
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const upgradeFarmingItems = async ({ activeUser, farmingItemID }) => {

    await activeUser.signTransaction({
        actions: [
            {
                account: PIXEL_FARMING_CONTRACT,
                name: "upgfarmitem",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    farmingitem_to_upgrade: +farmingItemID,
                    staked: true
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const upgradeItems = async ({ activeUser, item, level, farmingItem }) => {
    console.log(activeUser, item, level, farmingItem)
    await activeUser.signTransaction({
        actions: [
            {
                account: PIXEL_FARMING_CONTRACT,
                name: "upgradeitem",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    item_to_upgrade: item,
                    next_level: level,
                    staked_at_farmingitem: farmingItem
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const  whitelistIPFS = async ({ activeUser, images }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: "pixelfarming",
                name: "req2wipfs",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    owner: activeUser.accountName,
                    ipfs: images,
                    allow_to_everyone: false
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};