import {
    USER_INFO
} from "../actions/actionTypes/user.action-types";

const initialState = {
    userInfo: [],
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case USER_INFO: {
            const userInfo  = action.payload;

            return {
                ...state,
                userInfo,
            };
        }

        default:
            return state;
    }
};
