import React, {useState} from "react";

import Banner from '../images/title_banner.png'
import {Link} from "react-router-dom";

import "./BuildingHover.scss";


export default function BuildingHover({ number, image, buildingClickHandler, name, link }) {

    const [banner, setBanner] = useState(false)

    return (
        <Link to={link}>
            <div>
                <img
                    src={image}
                    className={`building building-${number}`}
                    onClick={buildingClickHandler}
                    alt={''}
                    onMouseEnter={() => setBanner(true)}
                    onMouseLeave={() => setBanner(false)}
                />
                {banner &&
                    <div
                        className={`banner banner-${number}`}
                        onMouseEnter={() => setBanner(true)}
                        onMouseLeave={() => setBanner(false)}
                    >
                        <img src={Banner} alt=""/>
                        <span>{name}</span>
                    </div>
                }
            </div>
        </Link>
    );
}