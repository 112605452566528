import React, {useState, useContext, useEffect} from 'react'

import CloseIcon from '../../images/close_btn.png'

import './UpgradeModal.css'
import {Link} from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import Header from "../Header/Header";
import DragBar from "../DragBar/DragBar";
import {getStaked, upgradeItems} from "../../services/wax.service";
import {UALContext} from "ual-reactjs-renderer";
import axios from "axios";



export default function UpgradeModal({close, items}) {
    const { activeUser } = useContext(UALContext);
    const [upgradeItemsSelected, setUpgradeItemSelected] = useState([])
    const [upgradeFarmingItemsSelected, setUpgradeFarmingItemsSelected] = useState(null)
    const [selectedItem, setSelectedItem] = useState([])
    const [dragBarValue, setDragBarValue] = useState(1)

    const [stakedItems, setStakedItems] = useState([])

    useEffect(() => {
        getStaked({activeUser})
            .then((items) => {
        const newArr = items.map(({asset_id, staked_items}) => {
        const requests = staked_items.map(item => {
            return axios.get('https://test.wax.api.atomicassets.io/atomicassets/v1/assets/' + item)
        });

        return axios.all(requests);
    })


    axios.all(newArr)
      .then(results => {
        const modifiedArr = items.map(({asset_id, staked_items}, index) => {
          return {
            asset_id: asset_id,
            staked_items: results[index]
          };
        });


        return modifiedArr;
      })
      .then(modifiedArr => {
        setStakedItems(modifiedArr)
      })
      .catch(error => {
        console.error(error);
      });

            })
            .catch((e) => {
                console.log(e)
            })
    }, [activeUser])


    const toggleActiveItem = (item, farmingItemId) => {
        if(upgradeItemsSelected.includes(item)){
            setUpgradeItemSelected([])
            setUpgradeFarmingItemsSelected(null)
            setSelectedItem(null)
        } else {
            upgradeItemsSelected.length = 0
            setUpgradeItemSelected([...upgradeItemsSelected, item])
            setUpgradeFarmingItemsSelected(farmingItemId)
            setSelectedItem(item.asset_id)
        }
    }


    const timeToUpgrade = (start_level, end_level) => {
        let time_to_upg = getUpgradeTime(start_level) - getUpgradeTime(end_level)
        // console.log(time_to_upg)
        return time_to_upg

    }


    const getUpgradeTime = (end_level) => {
        let total_time = 0
        let temp_tracker = 0
        let increasing_time = 320
        for(let i = 2; i <= end_level; ++i) {
            if(i % 5 === 0)
            {
                total_time += (temp_tracker * 5);
            }
            else
            {
                temp_tracker += increasing_time;
                total_time += increasing_time;
            }
        }
        return total_time;
    }


    // Current levelА
    const resDAO = (new_level, current_lvl, mining_rate) => {
        let miningRate_according2lvl = mining_rate;
        for(let i = 1; i < new_level; i++)
        miningRate_according2lvl = miningRate_according2lvl + (miningRate_according2lvl * 5 / 100);


        const upgrade_time  = timeToUpgrade(new_level) - timeToUpgrade(current_lvl);
        const resource_price = upgrade_time * miningRate_according2lvl;
        return {miningRate_according2lvl, resource_price}

    }

    const timeWrapper = (total_sec) => {
        let totalSeconds = total_sec;
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");
        return `${hours + ":" + minutes + ":" + seconds}`
    }



const getSlotsContent = () => {
        let content = [];
    stakedItems.map((item) => {
        item.staked_items.map(({data}) => {
             content.push(
                <div key={data.data?.asset_id} className="upgrade-info-block" onClick={data.data ? () => toggleActiveItem(data.data, item.asset_id) : null}>
                    <p className={'item-upgrade-info_name'}>{data.data?.data?.name}</p>
                    <div className={selectedItem !== data.data?.asset_id ? "upgrade-item upgrade-item_unactive" : 'upgrade-item upgrade-item_selected'}>
                        {data.data && <img src={`https://atomichub-ipfs.com/ipfs/${data.data?.data.img}`} alt=""/>}
                    </div>
                    <p style={data.data ? {opacity: 1} : {opacity: 0}} className={'item-upgrade-info'}>level 1</p>
                    <p className={'item-upgrade-info'}>{data.data?.asset_id}</p>
                </div>
            );
        })
    })
        return content;
    };



    // const getSlotsContent = item => {
    //     console.log(item)
    //     let content = []
    //     for (let i = 0; i < item.length; i++) {
    //         content.push(
    //             <div key={i} className="upgrade-info-block" onClick={item[i] ? () => toggleActiveItem(item[i]) : null}>
    //                 <p className={'item-upgrade-info_name'}>{item[i]?.data?.name}</p>
    //                 <div className={selectedItem !== item[i]?.asset_id ? "upgrade-item upgrade-item_unactive" : 'upgrade-item upgrade-item_selected'}>
    //                     {item[i]?.staked_items && <img src={`https://atomichub-ipfs.com/ipfs/${item[i]?.data?.img}`} alt=""/>}
    //                 </div>
    //                 <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-upgrade-info'}>/level 1/</p>
    //                 <p className={'item-upgrade-info'}>{item[i]?.asset_id}</p>
    //             </div>
    //         );
    //     }
    //
    //     return content;
    // };


    function handleUpgrade(upgradeItemsSelectedElement, upgradeFarmingItemSelected) {
        upgradeItems({activeUser, item: Number(upgradeItemsSelectedElement.asset_id) , level: dragBarValue, farmingItem: Number(upgradeFarmingItemSelected)})
    }



    return (
        <>
            <Header title={'UPGRADE ITEM'}  upgradeIcon={false} />
                <Link to={'/'}>
                    <div className="close-upgrade" onClick={close}>
                        <img src={CloseIcon} alt=""/>
                    </div>
                </Link>

            <div className={"upgrade-modal"}>
                <div className={"upgrade-modal-window"}>
                    {items.length ?
                    <>
                        <div className="sort-block">

                        </div>
                        <div className={"update-items-list"}>
                            {getSlotsContent()}
                        </div>
                        <SearchBar/>
                    </>
                        :
                        <div className={'no-assets'}>NO ASSETS</div>
                    }

                </div>

                {!upgradeItemsSelected.length ?
                    <div className="upgrade-modal-window-two">
                        <div className="upgrade-modal-window-two_title">
                            <h3>UPGRADE TO:</h3>
                        </div>
                    </div>
                    :
                    <div className="upgrade-modal-window-three">
                        <div className="upgrade-modal-window-two_title_two">
                            <h3>UPGRADE TO NEXT <br/> LEVEL</h3>
                        </div>
                        <div className="upgradeing-item">
                            {upgradeItemsSelected[0] && <img src={`https://atomichub-ipfs.com/ipfs/${upgradeItemsSelected[0]?.data?.img}`} alt=""/>}
                        </div>
                        <div className="upgrade-item-info">
                            {/*<div className="production">*/}
                            {/*    <p>Production</p>*/}
                            {/*    <p>160</p>*/}
                            {/*    <p>(+10)</p>*/}
                            {/*</div>*/}
                            <div className="level">
                                <p>New level {dragBarValue}</p>
                            </div>
                            {/*<div className="asset-id">*/}
                            {/*    <p>ID /{upgradeItemsSelected[0]?.asset_id}/</p>*/}
                            {/*</div>*/}
                            <div className="minted">
                                <p>Mining rate: {resDAO(dragBarValue, upgradeItemsSelected[0]?.data?.level, upgradeItemsSelected[0]?.data?.miningRate).miningRate_according2lvl.toFixed(7)}</p>

                            </div>
                            <div className="max-levels">
                                <p>Max Levels</p>
                                <p>{upgradeItemsSelected[0]?.data?.maxLevel}</p>
                            </div>
                            <div className="faction">
                                <p>Faction</p>
                                <p>{upgradeItemsSelected[0]?.data?.faction}</p>
                            </div>

                        </div>
                        <div className={'time-to-upgrade'}>
                            <p className={'upg-price'}>
                                <p>PRICE:</p>
                                <p>{resDAO(dragBarValue, upgradeItemsSelected[0]?.data.level, upgradeItemsSelected[0]?.data.miningRate).resource_price.toFixed(4)}</p>
                            </p>
                            <p>Time: {timeWrapper(timeToUpgrade(dragBarValue, upgradeItemsSelected[0]?.data.level))}</p>
                        </div>
                        <div className={'drag-bar'}>
                            <DragBar
                                maxLevel={upgradeItemsSelected[0].data.maxLevel}
                                level={upgradeItemsSelected[0].data.level +1}
                                setDragBarValue={setDragBarValue}
                                dragBarValue={dragBarValue}
                            />
                        </div>
                        <div className="upgrade-btn" onClick={() => handleUpgrade(upgradeItemsSelected[0], upgradeFarmingItemsSelected)}>
                            <span>UPGRADE</span>
                        </div>
                    </div>
                }
            </div>
        </>

    )
}