import React from 'react'

const CurrencyField = props => {

    return (
        <div className="row currencyInput">
            <div className="col-md-6 numberContainer">
                {props.loading ? (
                    <div className="spinnerContainer">
                        <props.spinner />
                    </div>
                ) : (
                    <input
                        onChange={(e) => props.setInputResValue(e.target.value)}
                        className="currencyInputField"
                        placeholder="0.0"
                        value={props.value}
                    />
                )}
            </div>
            <div className="col-md-6 tokenContainer">
                <span className="tokenName">{props.tokenName}</span>
                <div className="balanceContainer">
                    <span
                        className="balanceAmount"
                        onClick={() => {props.setInputResValue(props.balance?.toFixed(4))}}>
                        Balance: {props.balance?.toFixed(4)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CurrencyField