import React, {useState, useContext, useEffect} from 'react';

import './SwapTokenModal.scss'
import CloseIcon from "../../images/close_btn.png";
import GreenBtn from '../../images/green_btn.png'
import UpdateInfoIcon from '../../images/icons8-update-32.png'
import {getMiningConfig, swapToDao} from "../../services/wax.service";
import {UALContext} from "ual-reactjs-renderer";
import CurrencyField from "../CurrencyField/CurrencyField";


function SwapTokenModal({isModalOpen, setIsModalOpen, resources}) {
    const { activeUser } = useContext(UALContext);
    const [selectedResource, setSelectedResource] = useState('wood');
    const [inputResValue, setInputResValue] = useState(null);
    const [getMiningConfigState, setGetMiningConfigState] = useState([]);
    const [getMiningInfo, setGetMiningInfo] = useState('0 PXL');


    useEffect(() => {
        getMiningConfig()
            .then((data) =>{
                setGetMiningConfigState(data)
            })
            .catch((error) => {
                console.log(error)
            })
    },[])


    function openModal() {
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    function handleTokenChange(event) {
        setSelectedResource(event.target.value);
    }

    function calculateMiningStatistic(mined_tokens, token_mining_rate, last_update) {
        let mined_amount = parseFloat(mined_tokens.split(" ")[0]);
        let current_time = Date.now() / 1000;
        // console.log(current_time)
        // console.log(last_update)
        let time_elapsed = current_time - last_update;
        // console.log(time_elapsed)
        let mined_token_amount = mined_amount + (time_elapsed * parseFloat(token_mining_rate.split(" ")[0]));
        // console.log(mined_token_amount + " PXL")
        setGetMiningInfo(mined_token_amount + " PXL")
    }




    function renderAvailablePrice() {

        let filterResource = resources.filter(item => {
            return item.resource_name === selectedResource
        })

        return +filterResource[0]?.amount
    }


    function handleSwapToDaoToken() {
        swapToDao({activeUser, resource: selectedResource, amount2swap: inputResValue})
    }


    function getBalanceDAO() {
        // (resources_to_swap / total_resources ) * mined_tokens  = DAO tokens to receive
        return ((inputResValue / getMiningConfigState[0]?.total_resources ) * Number(getMiningInfo.split(' ')[0])).toFixed(4)
    }

    function renderSelectedResource() {
        let content = []

            content.push(
                <select value={selectedResource} onChange={handleTokenChange}>
                    {resources.map(item =>
                        <option value={item.resource_name}>{item.resource_name}</option>
                    )}
                </select>
            )

        return content
        }


    function updatedPoolInfo() {
        setTimeout(() => {
            return calculateMiningStatistic(getMiningConfigState[0]?.mined_tokens, getMiningConfigState[0]?.token_mining_rate, getMiningConfigState[0]?.last_update);
        }, 1000)
    }

    return (
        <div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="close-swap-modal" onClick={closeModal}>
                            <img src={CloseIcon} alt=""/>
                        </div>

                            <div className="appBody">
                                <div className="swapContainer">
                                    <h1>Swap To DAO</h1>
                                    <div className="swapBody">
                                        <CurrencyField
                                            setInputResValue={setInputResValue}
                                            field="input"
                                            value={inputResValue}
                                            tokenName={renderSelectedResource()}
                                            getSwapPrice={undefined}
                                            signer={undefined}
                                            balance={renderAvailablePrice()} />
                                        <CurrencyField
                                            field="output"
                                            tokenName="DAO"
                                            value={getBalanceDAO()}
                                            signer={undefined}
                                            balance={0.000}
                                            spinner={undefined}
                                            loading={undefined} />
                                    </div>
                                    <div className="swap-btn">
                                            <div className="button-swap" id="export-button" onClick={handleSwapToDaoToken}>
                                                <img src={GreenBtn} alt="" />
                                                    <p>SWAP</p>
                                            </div>
                                    </div>
                                </div>
                            </div>

                        <div className="modal-pool-content">
                            <div className="icon-update-info" onClick={updatedPoolInfo}>
                                <img src={UpdateInfoIcon} alt=""/>
                            </div>
                            <h1>Pool</h1>
                            <p>Total resources:</p>
                            <span className={'pool-info'}>{getMiningConfigState[0]?.total_resources}</span>
                            <p>Total tokens staked: </p>
                            <span className={'pool-info'}>{getMiningConfigState[0]?.total_tokens_staked}</span>
                            <p>Mined tokens:</p>
                            <span className={'pool-info'}>{getMiningInfo}</span>
                            <p>Last update:</p>
                            <span className={'pool-info'}>{getMiningConfigState[0]?.last_update}</span>
                            <p>Token mining rate:</p>
                            <span className={'pool-info'}>{getMiningConfigState[0]?.token_mining_rate}</span>
                        </div>
                    </div>

                </div>
            )}
            <button onClick={openModal}>Open Modal</button>
        </div>
    );
}

export default SwapTokenModal;