import React, {useState, useEffect} from "react";

import BlandModal from "../../components/modals/BlandModal";
import MiningModal from "../../components/modals/MiningModal";
import UpgradeModal from "../../components/modals/UpgradeModal";
import StakingModal from "../../components/modals/StakingModal";

import MapImageMapper from "../../components/MapImageMapper";
import MapBg from "../../images/pixel_dao_bg.png";
import BackIcon from "../../images/back_icon.png";

import {Switch, Route, Link} from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";

import './MainMap.css'

import {
    MAP_IMAGE_MAP_HEIGHT_2,
    MAP_IMAGE_MAP_HEIGHT_3,
    MAP_IMAGE_MAP_HEIGHT_4,
    MAP_IMAGE_MAP_HEIGHT_5,
    MAP_IMAGE_MAP_HEIGHT_6,
    MAP_IMAGE_MAP_HEIGHT_7,
    MAP_IMAGE_MAP_HEIGHT_INITIAL,
    MAP_IMAGE_MAP_WIDTH_2,
    MAP_IMAGE_MAP_WIDTH_3,
    MAP_IMAGE_MAP_WIDTH_4,
    MAP_IMAGE_MAP_WIDTH_5,
    MAP_IMAGE_MAP_WIDTH_6,
    MAP_IMAGE_MAP_WIDTH_7,
    MAP_IMAGE_MAP_WIDTH_INITIAL
} from "../../constants/map.constants";
import SwapTokenModal from "../../components/modals/SwapTokenModal";



export default function MainMap({items, collectionItems, farmingItems, stakedItems}) {

    const [isBlandingModal, setIsBlandingModal] = useState(false);
    const [isMainingModal, setIsMainingModal] = useState(false);


    const [imageMapWidth, setImageMapWidth] = useState(MAP_IMAGE_MAP_WIDTH_7);
    const [imageMapHeight, setImageMapHeight] = useState(MAP_IMAGE_MAP_HEIGHT_7);

    useEffect(() => {
        const resizeWindow = () => {
            if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_6) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_7);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_7);
            } else if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_5) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_6);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_6);
            } else if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_4) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_5);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_5);
            } else if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_3) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_4);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_4);
            } else if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_2) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_3);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_3);
            } else if (+window.innerWidth < +MAP_IMAGE_MAP_WIDTH_INITIAL) {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_2);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_2);
            } else {
                setImageMapWidth(MAP_IMAGE_MAP_WIDTH_INITIAL);
                setImageMapHeight(MAP_IMAGE_MAP_HEIGHT_INITIAL);
            }
        };

        resizeWindow();

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    const handleClosedHouse = () => {
        setIsMainingModal(false)
        setIsBlandingModal(false)
    }

    const handleBlandHouse = () => {
        setIsBlandingModal(true)
    }

    const handleMiningHouse = () => {
        setIsMainingModal(true)
    }

    return (
        <div className="main-map-container">

            <Switch>
                <Route exact path="/">
                    <Avatar />
                    <MapImageMapper
                        openMiningModal={setIsMainingModal}
                        openBlandingModal={setIsBlandingModal}
                        clickBlandHouse={handleBlandHouse}
                        clickMiningHouse={handleMiningHouse}
                    />
                    <img src={MapBg} width={imageMapWidth} height={imageMapHeight} alt="lands" />
                </Route>

                <Route exact path="/bland">
                    <Avatar />
                    <BlandModal
                        isShow={isBlandingModal}
                        close={handleClosedHouse}
                        items={items}
                        collectionItems={collectionItems}
                    />
                    <img src={MapBg} width={imageMapWidth} height={imageMapHeight} alt="lands" />
                </Route>

                <Route exact path="/mining">
                    <Avatar />
                    <MiningModal
                        isShow={isMainingModal}
                        close={handleClosedHouse}
                        items={items}
                        farmingItems={farmingItems}
                        stakedItems={stakedItems}
                    />
                    <img src={MapBg} width={imageMapWidth} height={imageMapHeight} alt="lands" />
                </Route>

                <Route exact path="/upgrade">
                    <Avatar />
                    <UpgradeModal items={items} close={handleClosedHouse} />
                    <img src={MapBg} width={imageMapWidth} height={imageMapHeight} alt="lands" />
                </Route>

                <Route exact path="/staking">
                    <Avatar />
                    <StakingModal items={farmingItems} close={handleClosedHouse} stakedItems={stakedItems} />
                    <img src={MapBg} width={imageMapWidth} height={imageMapHeight} alt="lands" />
                </Route>

                <Route exact path="/pixel-editor">
                    <Link to="/">
                        <div className="back-icon">
                            <img src={BackIcon} alt="" />
                            <p>BACK</p>
                        </div>
                    </Link>
                    <iframe frameBorder="0" id="pixelEditorWindow" title="pixel-editor" src="./pixel-editor.html" />
                </Route>
            </Switch>
        </div>
    );
}