import React, {useContext, useState} from "react";
import {UALContext} from "ual-reactjs-renderer";

import BigLogo from '../../images/logIn_logo.png'
import LigInBtn from '../../images/orange-btn-login.png'
import LigInBtnHover from '../../images/orange-btn-login-hover.png'

import './LogIn.css'




export default function LogIn() {

    const { activeUser, showModal, logout } = useContext(UALContext);
    const [login, setLogin] = useState(false);
    const [activeBtnLogin, setActiveBtnLogin] = useState(false);

    const handlerLogIn = () => {
        logout();
        showModal();

        setLogin(true);
    }

    const handlerLogOut = () => {
        logout();

        setLogin(false);
    };

    return (
        <div className={'login-container'}>
            <div className={'top-log-in'}>
                <div className={'big-logo'}>
                    <img src={BigLogo} alt=""/>
                </div>
                <div className="btn-login"
                     onMouseEnter={() => setActiveBtnLogin(true)}
                     onMouseLeave={() => setActiveBtnLogin(false)}
                     onClick={handlerLogIn}
                >
                    {!activeBtnLogin ?
                        <img src={LigInBtn} alt=""/>
                    :
                        <img src={LigInBtnHover} alt=""/>
                    }
                    <span>LOGIN</span>
                </div>
                {/*<div className="logo">*/}
                {/*    <img src={BigLogo} alt=""/>*/}
                {/*</div>*/}
                {/*<div className="login-btn">*/}
                {/*    <img src={LigInBtn} alt="" onClick={handlerLogIn}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}