import React from 'react'
import SearchIcon from "../../images/search_icon.png";

import './SearchBar.css'


export default function SearchBar() {
    return (
        <div className="search-bar">
            <input type="text"/>
            <img src={SearchIcon} alt=""/>
        </div>
    )
}