import React, {useContext} from 'react'
import {UALContext} from "ual-reactjs-renderer";

import AvatarDefoult from '../../images/defoult_avatar.png'
import UpgradeAvatar from '../../images/upgrade_icon.png'


import ToolTip from "../ToolTip/ToolTip";

import './Avatar.scss'



export default function Avatar() {
    const { activeUser } = useContext(UALContext);

    return (
        <div className="avatar">
            <div className="avatar-logo">
                <img src={AvatarDefoult} alt=""/>
                <div className="upgrade-avatar">
                    <img src={UpgradeAvatar} className={'avatar-upgrade'} alt=""/>
                </div>

            </div>
            <div className="avatar-info">
                {activeUser?.accountName}
            </div>
            <ToolTip/>
        </div>
    )
}