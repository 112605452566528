import React, {useEffect, useState, useContext} from 'react'

import CloseIcon from "../../images/close_btn.png";

import PickDefoult from "../../images/pick.png";

import DAOToken from '../../images/DAO_token.png'

import UnactiveBtn from "../../images/unactive_btn.png";
import GreenBtn from "../../images/green_btn.png";

import BigBtn from "../../images/big-btn.png";
import {Link} from "react-router-dom";

import './BlandModal.css'
import {
    createBlendV,
    getBlends, getBlendVotes,
    getCollectionItemsByTemplateId,
    getReqIPFS,
    getWhiteListIPFS,
    whitelistIPFS
} from "../../services/wax.service";
import {UALContext} from "ual-reactjs-renderer";
import SearchBar from "../SearchBar/SearchBar";
import Header from "../Header/Header";
import BackIcon from "../../images/back_icon.png";
import axios from "axios";



export default function BlandModal({ close, selectedMenu, items, collectionItems}) {
    const { activeUser } = useContext(UALContext);

    const [selectedBlandMenu, setSelectedBlandMenu] = useState(0)
    const [wipfs, setWipfs] = useState([])
    const [req2wipfs, setReq2Wipfs] = useState([])

    const [selectedItemForBland, setSelectedItemForBland] = useState(null)
    const [selectedItemForBlandTwo, setSelectedItemForBlandTwo] = useState(null)

    const [selectedPicForBland, setSelectedPicForBland] = useState(null)

    const [blends, setBlends] = useState([])
    const [vouting, setVouting] = useState([])


    const infoForBland = (item1, item2) => {
        let a = [item1?.immutable_data?.miningRate, item2?.immutable_data?.miningRate]
        let res = a[0] + a[1]

        if (a[0] && a[1]){
            return res.toFixed(2)
        } else {
            return 0
        }

    }

    console.log(vouting)


    const handleOpenChooseImgModal = () => {
        setSelectedBlandMenu(5)
    }

    const handleSelectPicForBland = (ipfs) => {
        setSelectedPicForBland(ipfs)
        setSelectedBlandMenu(0)
    }

    const handleDeleteSelectedPic = () => {
        setSelectedPicForBland(null)
    }

    function calculatePercentage(votes) {
        const totalVotes = vouting.reduce((total, obj) => total + obj.votes, 0);
        const percentage = (votes / totalVotes) * 100;
        return percentage.toFixed(2) + "%";
    }

    useEffect(() => {
        getBlendVotes()
            .then(data => {
                const newArray = data.map(async (item) => {
                    const blend_components = await Promise.all(item.blend_components.map(async id => {
                        const response = await axios.get(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates/pixelfarming/${id}`);
                        return response.data.data
                    }))

                    return {
                        ...item,
                        blend_components: blend_components
                    }
                });

                Promise.all(newArray).then(res=> setVouting(res))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    useEffect(() => {
        getWhiteListIPFS().then((data) => {
            let filteredData = data.filter((item) => {
                return item.owner === activeUser.accountName
            })
            setWipfs(filteredData)
        })

        getReqIPFS().then((data) => {
            let filteredData = data.filter((item) => {
                return item.owner === activeUser.accountName
            })

            setReq2Wipfs(filteredData)

        })

    }, [setReq2Wipfs, activeUser])

    useEffect(() => {
        if (localStorage.getItem('pic-hesh')){
            let heshIPFS = localStorage.getItem('pic-hesh')

            whitelistIPFS({activeUser: activeUser, images: heshIPFS})
                .then(() => {
                    localStorage.removeItem('pic-hesh')
                })
                .catch((err) => {
                    localStorage.removeItem('pic-hesh')
                    console.log(err)
                })
        }
    },[])

    useEffect(() => {
       getBlends().then((data) => {
           setBlends(data)
       })
    },[])


    function handleBlandItem(asset) {
        if (!selectedItemForBland){
            setSelectedItemForBland(asset)
        }else{
            setSelectedItemForBlandTwo(asset)
        }
    }


    const getSlotsContentForInventory = (item) => {
        let content = []
        for (let i = 0; i < item.length; i++) {
                content.push(
                            <div className="mining-info-block">
                                <p className={'item-mining-info_name'}>{item[i]?.immutable_data.name}</p>
                                <div className="mining-item mining-item_unactive inventory-item" onClick={() => handleBlandItem(item[i])}>
                                    {item[i] && <img src={`https://atomichub-ipfs.com/ipfs/${item[i]?.immutable_data.img}`} alt=""/>}
                                </div>
                                {/*<p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>/level 1/</p>*/}
                                <p style={item[i] ? {opacity: 1} : {opacity: 0}} className={'item-mining-info'}>TEMP ID {item[i]?.template_id}</p>
                            </div>
                );
        }
        return content;
    };


    function handlerCreateBlandV() {
        createBlendV({activeUser,
            ipfs: selectedPicForBland,
            name: 'pic1',
            components: [selectedItemForBland?.template?.template_id, selectedItemForBlandTwo?.template?.template_id]}
        )
    }

    function handleSelectSchema(item) {
        setSelectedItemForBland(item?.blend_components[0])
        setSelectedItemForBlandTwo(item?.blend_components[1])
        setSelectedBlandMenu(0)
    }


    function countProcent(index,array) {
        const totalVotes = array.reduce((acc, curr) => acc + curr.votes, 0);

        const updatedVotes = array.map(vote => {
            return {
                ...vote,
                procent: (vote?.votes / totalVotes) * 100
            }
        });
        console.log(updatedVotes[index])
        return updatedVotes[index]
    }



    return (
        <>

            <Link to={'/'}>
                <div className="close-bland" onClick={close}>
                    <img src={CloseIcon} alt=""/>
                </div>
            </Link>

            <div className={"bland-modal"}>

                {selectedBlandMenu === 0 &&
                    <>
                        <Header
                            title={'CREATE NEW BLEND'}
                                upgradeIcon={false}
                        />
                        <div className={"bland-modal_main-window"}>
                            <div className="bland-modal_window_top">
                                <div className="bland-items_list">
                                    <div className="bland-item bland-item_unactive" onClick={() => setSelectedItemForBland(null)}>
                                        {selectedItemForBland && <img src={`https://atomichub-ipfs.com/ipfs/${selectedItemForBland?.immutable_data?.img}`} alt=""/>}
                                    </div>
                                    <span className={'bland-item_sign'}>+</span>
                                    <div className="bland-item bland-item_unactive" onClick={() => setSelectedItemForBlandTwo(null)}>
                                        {selectedItemForBlandTwo && <img src={`https://atomichub-ipfs.com/ipfs/${selectedItemForBlandTwo?.immutable_data?.img}`} alt=""/>}
                                    </div>
                                    <span className={'bland-item_sign'}>=</span>
                                    <div className="bland-item bland-item_choose" >
                                        {selectedPicForBland ?
                                            <img onClick={() => handleDeleteSelectedPic()} src={`https://atomichub-ipfs.com/ipfs/${selectedPicForBland}`} alt=""/>
                                            :
                                            <span onClick={handleOpenChooseImgModal}>CHOOSE IMAGE</span>
                                        }
                                    </div>
                                    <div className="bland-paper">
                                        <div className="bland-paper_info">
                                            <div className="bland-paper_info-left">
                                                <p>Production</p>
                                                <p>Level</p>
                                                <p>Max levels</p>
                                                <p>Faction</p>
                                            </div>
                                            <div className="bland-paper_info-right">
                                                {selectedItemForBland ?
                                                    <p>{infoForBland(selectedItemForBland,selectedItemForBlandTwo)}</p>
                                                    :
                                                    <p>0</p>
                                                }
                                                <p>/number/</p>
                                                {selectedItemForBland ?
                                                    <p>{selectedItemForBland?.immutable_data?.maxLevel}</p>
                                                    :
                                                    <p>0</p>
                                                }
                                                {selectedItemForBland ?
                                                    <p>{selectedItemForBland?.immutable_data?.faction}</p>
                                                    :
                                                    <p>0</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="bland-price">
                                            <img src={DAOToken} alt=""/>
                                            <span>000 000</span>
                                        </div>
                                        {!selectedPicForBland || !selectedItemForBland || !selectedItemForBlandTwo ?
                                            <div className={'blanding-btn'}>
                                                <img src={UnactiveBtn} alt=""/>
                                                <span>BLEND</span>
                                            </div>
                                            :
                                            <div className={'blanding-btn'} style={{cursor: "pointer"}} onClick={() => handlerCreateBlandV()}>
                                                <img src={GreenBtn} alt=""/>
                                                <span>BLEND</span>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>
                            <div className="bland-modal_window_bottom">
                                <div className="bland-modal_window_bottom-title">
                                    <h3>INVENTORY</h3>
                                </div>
                                <div className={"available-items"}>
                                    {/*<div className="bland-info-block">*/}
                                    {/*    <p className={'item-bland-info_name'}>PICK</p>*/}
                                    {/*    <div className="bland-item bland-item_unactive inventory-item">*/}
                                    {/*        <img src={PickDefoult} alt=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <p className={'item-bland-info'}>/level 1/</p>*/}
                                    {/*    <p className={'item-bland-info'}>/ID 1234566/</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="bland-info-block">*/}
                                    {/*    <p className={'item-bland-info_name'}>PICKAXE</p>*/}
                                    {/*    <div className="bland-item bland-item_unactive inventory-item">*/}
                                    {/*        <img src={PickAxe} alt=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <p className={'item-bland-info'}>/level 1/</p>*/}
                                    {/*    <p className={'item-bland-info'}>/ID 1234566/</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {/*<div className="bland-item bland-item_unactive inventory-item"></div>*/}
                                    {getSlotsContentForInventory(collectionItems)}
                                </div>
                                <SearchBar/>
                            </div>
                        </div>
                    </>

                }

                {selectedBlandMenu === 1 &&
                    <>
                        <Header title={'Created blends'}  upgradeIcon={false} />
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>
                            <div className={"created-blands-list"}>
                                <div className="created-blands-list_item">
                                    <div className="name">
                                        <p>Pick</p>
                                    </div>
                                    <div className="picture">
                                        <img src={PickDefoult} alt=""/>
                                    </div>
                                    <div className="btn-voting">
                                        <span>PUSH TO VOTING</span>
                                    </div>
                                </div>

                                <div className="created-blands-list_item">
                                    <div className="name">
                                        <p>Pick</p>
                                    </div>
                                    <div className="picture">
                                        <img src={PickDefoult} alt=""/>
                                    </div>
                                    <div className="btn-voting">
                                        <span>PUSH TO VOTING</span>
                                    </div>
                                </div>

                                <div className="created-blands-list_item">
                                    <div className="name">
                                        <p>Pick</p>
                                    </div>
                                    <div className="picture">
                                        <img src={PickDefoult} alt=""/>
                                    </div>
                                    <div className="btn-voting">
                                        <span>PUSH TO VOTING</span>
                                    </div>
                                </div>
                            </div>
                            <SearchBar/>
                        </div>
                    </>

                }


                {selectedBlandMenu === 2 &&
                    <>
                        <Header title={'Item`s schemas'}  upgradeIcon={false} />
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>
                            <div className="schemas">
                                {blends.map(item =>
                                    <div className="schema_block">
                                        <div className="schema_block_left">
                                            <h3>NAME</h3>
                                            <div className="schema_block_left_img">
                                                <img src={`https://atomichub-ipfs.com/ipfs/${item?.resulting_item?.immutable_data.img}`} alt=""/>
                                            </div>
                                            <p className="amount">1000/1000</p>
                                        </div>
                                        <div className="schema_block_right">
                                            <div className="schema_about">
                                                <div className="schema_about-left">
                                                    <p>Production</p>
                                                    <p>Level</p>
                                                    <p>Max levels</p>
                                                    <p>Faction</p>
                                                </div>
                                                <div className="schema_about-right">
                                                    <p>100/h</p>
                                                    <p>/number/</p>
                                                    <p>100</p>
                                                    <p>/number/</p>
                                                </div>
                                            </div>
                                            <div className="schema_bland-btn" onClick={() => handleSelectSchema(item)}>
                                                {/*<p>Bland this items</p>*/}
                                                <img src={BigBtn} alt=""/>
                                                <div className="schema_bland-btn_inside">
                                                    <div className="schema-item-frt">
                                                        <div className="pic-img">
                                                            <img src={`https://atomichub-ipfs.com/ipfs/${item?.blend_components[0]?.immutable_data.img}`} alt=""/>
                                                        </div>
                                                        <span>Pic1</span>
                                                    </div>
                                                    <div>+</div>
                                                    <div className="schema-item-snd">
                                                        <div className="pic-img">
                                                            <img src={`https://atomichub-ipfs.com/ipfs/${item?.blend_components[1]?.immutable_data.img}`} alt=""/>
                                                        </div>
                                                        <span>Pic2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="schema-or">
                                                <p>or buy instantly</p>
                                            </div>
                                            <div className="schema-price">
                                                <img src={GreenBtn} alt=""/>
                                                <div className="schema-price_inside">
                                                    <img src={DAOToken} alt=""/>
                                                    <span>000 000</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <SearchBar/>
                        </div>
                    </>

                }

                {selectedBlandMenu === 3 &&
                    <>
                        <Header title={'Paint new picture'}  upgradeIcon={false} />
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>

                            <div className="paint-new-picture_list">

                                <Link to='/pixel-editor'>
                                    <div className="btn-create-img">
                                        <div className="create">
                                            <div className="text-in-btn">
                                                <div className="text-top">
                                                    <img src={DAOToken} alt=""/>
                                                    <p>100</p>
                                                </div>
                                                <span>+</span>
                                            </div>
                                        </div>
                                        <p className={'item-bland-info'}>Create New</p>
                                    </div>
                                </Link>

                                {wipfs.map(item =>
                                    <div className="bland-info-block">
                                        <p className={'item-bland-info_name'}>AXE</p>
                                        <div className={"bland-item blanding-item_unactive"} >
                                            <img src={`https://gateway.pinata.cloud/ipfs/${item.ipfs}`} alt=""/>
                                        </div>
                                        <p className={'item-bland-info'}><input className={'puclic-check'} type="checkbox"/>for public use</p>
                                    </div>
                                )}

                                {req2wipfs.map(item =>
                                    <div className="bland-info-block">
                                        <p className={'item-bland-info_name'}>AXE</p>
                                        <div className={"bland-item blanding-item_unactive"}>
                                            <div className="review-layer">
                                                <p>In review</p>
                                            </div>
                                            <img src={`https://gateway.pinata.cloud/ipfs/${item.ipfs}`} alt=""/>
                                        </div>
                                        <p style={{opacity: '0'}} className={'item-bland-info'}><input className={'puclic-check'} type="checkbox"/>for public use</p>
                                    </div>
                                )}
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>
                                <div className="bland-info-block">
                                    <p className={'item-bland-info_name'}></p>
                                    <div className={"bland-item blanding-item_unactive"}></div>
                                    <p className={'item-bland-info'}></p>
                                    <p className={'item-bland-info'}></p>
                                </div>

                            </div>
                            <SearchBar/>
                        </div>
                    </>
                }

                {selectedBlandMenu === 4 &&
                    <>
                        <Header title={'Public gallery'}  upgradeIcon={false} />
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>
                            {/*<SearchBar/>*/}
                        </div>
                    </>
                }

                {selectedBlandMenu === 5 &&
                    <>
                        <Header title={'Choose image'}  upgradeIcon={false} />
                        <div className="back-icon_pic" onClick={() => setSelectedBlandMenu(0)}>
                            <img src={BackIcon} alt=""/>
                        </div>
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>

                            <div className="paint-new-picture_list">
                                {wipfs.map(item =>
                                    <div className="bland-info-block" onClick={() => handleSelectPicForBland(item.ipfs)}>
                                        <p className={'item-bland-info_name'}>AXE</p>
                                        <div className={"bland-item blanding-item_unactive"}>
                                            <img src={`https://gateway.pinata.cloud/ipfs/${item.ipfs}`} alt=""/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <SearchBar/>
                        </div>
                    </>
                }

                {selectedBlandMenu === 6 &&
                    <>
                        <Header title={'Voting'}  upgradeIcon={false} />
                        <div className={"upgrade-modal-window"}>
                            <div className="sort-block">

                            </div>
                            <div className="voting-wrapper">
                                {vouting.map((item, index, array) =>
                                    <div key={index} className="voting-block_container">
                                        <div className="voting-block">
                                            <div className="voting-main-img">
                                                <p>{item.item_name}</p>
                                                <img src={`https://atomichub-ipfs.com/ipfs/${item.item_img}`} alt=""/>
                                            </div>
                                            <div className="voting-info">
                                                <div className="info">
                                                    <div className="voting-info_left">
                                                        <p>Creator:</p>
                                                        <p>Production:</p>
                                                        <p>Level Max:</p>
                                                        <p>levels:</p>
                                                        <p>Faction:</p>
                                                    </div>
                                                    <div className="voting-info_right">
                                                        <p>{item.owner}</p>
                                                        <p>200/h</p>
                                                        <p>/number/</p>
                                                        <p>100</p>
                                                        <p>/name/</p>
                                                    </div>
                                                </div>
                                                <div className="blend">
                                                    <div className="item-one">
                                                        <img src={`https://atomichub-ipfs.com/ipfs/${item.blend_components[0]?.immutable_data?.img}`} alt=""/>
                                                        <span>Pick 1</span>
                                                    </div>
                                                    <div className=""><p>+</p></div>
                                                    <div className="item-two">
                                                        <img src={`https://atomichub-ipfs.com/ipfs/${item.blend_components[1]?.immutable_data?.img}`} alt=""/>
                                                        <span>Pick 2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="voting-percent">
                                                <span>{calculatePercentage(item.votes)}</span>
                                                <p>000 000 DAO</p>
                                                <p>{item.votes} votes</p>
                                            </div>
                                        </div>
                                        <div className="vote-btn_contant">
                                            <div className="price-vote">
                                                <img src={DAOToken} alt=""/>
                                                <p>000 000</p>
                                            </div>
                                            <button>VOTE</button>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <SearchBar/>
                        </div>
                    </>
                }
                <div className="bland-menu">
                    <div className={selectedBlandMenu === 0 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(0)}>
                        <p>CREATE NEW BLAND</p>
                    </div>
                    <div className={selectedBlandMenu === 1 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(1)}>
                        <p>CREATED BLANDS</p>
                    </div>
                    <div className={selectedBlandMenu === 2 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(2)}>
                        <p>CREATE ITEM WITH A SCHEMA</p>
                    </div>
                    <div className={selectedBlandMenu === 3 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(3)}>
                        <p>PAINT NEW PICTURE</p>
                    </div>
                    <div className={selectedBlandMenu === 4 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(4)}>
                        <p>GALLERY</p>
                    </div>
                    <div className={selectedBlandMenu === 6 ? "bland-menu_block_active" : "bland-menu_block"} onClick={() => setSelectedBlandMenu(6)}>
                        <p>VOTING</p>
                    </div>
                </div>
                {/*<BlandMenu setSelectedBlandMenu={setSelectedBlandMenu} selectedBlandMenu={selectedBlandMenu} selectedMenu={selectedMenu}/>*/}
            </div>

        </>
    )
}