import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import { Provider } from 'react-redux';
import { UALProvider } from "ual-reactjs-renderer";
import { createStore } from 'redux';
import { waxChain, waxAuthenticators } from "./blockchain";
import { reducers } from "./redux/reducers";

import reportWebVitals from './reportWebVitals';

import "./styles/index.scss";

export const store = createStore(
    reducers
);

ReactDOM.render(

        <UALProvider
            chains={[waxChain]}
            authenticators={waxAuthenticators}
            appName={'PixelFarming'}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </UALProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();