import React, {memo} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export const DragBar = memo(function({maxLevel, level, setDragBarValue, dragBarValue}) {

    function valuetext(value) {
        setDragBarValue(value)
    }

    return (
        <Box sx={{ width: 200, margin: '0 auto'}}>
            <Slider
                key={`slider-${valuetext}`}
                aria-label="Temperature"
                defaultValue={1}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={level}
                max={maxLevel}
            />
        </Box>
    );
})

export default DragBar;