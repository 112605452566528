import React, { useEffect, useContext, useState } from "react";
import { getCollectionItems, getItems } from "./services/wax.service";
import LogIn from "./containers/LogIn/LogIn";
import MainMap from "./containers/MainMap/MainMap";
import { UALContext } from "ual-reactjs-renderer";
import "./App.scss";

export default function App() {
    const { activeUser } = useContext(UALContext);

    const [items, setItems] = useState([]);
    const [collectionItems, setCollectionItems] = useState([]);
    const [farmingItems, setFarmingItems] = useState([]);
    const [stakedItems, setStakedItems] = useState([]);

    useEffect(() => {
        async function fetchItems() {
            try {
                const res = await getItems(activeUser);
                const resultItems = res.filter(
                    (item) => item.schema.schema_name === "item1"
                );
                const resultFarmingItems = res.filter(
                    (item) => item.schema.schema_name === "farmingitem1"
                );

                setItems(resultItems);
                setFarmingItems(resultFarmingItems);
            } catch (error) {
                console.log(error);
            }
        }

        if (activeUser) {
            fetchItems();
        }
    }, [activeUser]);

    useEffect(() => {
        async function fetchCollectionItems() {
            try {
                const res = await getCollectionItems();
                setCollectionItems(res);
            } catch (error) {
                console.log(error);
            }
        }

        fetchCollectionItems();
    }, []);

    // useEffect(() => {
    //     getStakedItems({activeUser})
    //         .then((items) => {
    //             setStakedItems(items)
    //         })
    //         .catch((e) => {
    //             console.log(e)
    //         })
    // }, [activeUser])

    const isLoggedIn = !!activeUser;

    return isLoggedIn ? (
        <div className={"map-wrapper"}>
            <MainMap
                items={items}
                collectionItems={collectionItems}
                farmingItems={farmingItems}
                stakedItems={stakedItems}
            />
        </div>
    ) : (
        <LogIn />
    );
}