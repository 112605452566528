import React, { useState } from "react";

import Building1Hover from "../images/shrine.png";
import Building2Hover from "../images/mining.png";
import Building3Hover from "../images/upgrade_house.png";
import BuildingHover from "./BuildingHover";


export default function BuildingHovers({openMiningModal, openUpdateModal, openBlandingModal, clickBlandHouse, clickMiningHouse, clickUpgradeHouse, clickStakingHouse}) {
    const [buildingIdToShow, setBuildingIdToShow] = useState(null);

    //ORDER BY BUILDING ID
    const buildings = [
        { buildingName: 'SHALE', image: Building1Hover, buildingClickHandler: clickBlandHouse, link: '/bland'},
        { buildingName: 'MINING', image: Building2Hover, buildingClickHandler: clickMiningHouse, link: '/mining'},
        { buildingName: 'UPGRADE', image: Building3Hover, buildingClickHandler: clickUpgradeHouse, link: '/upgrade'},
        { buildingName: 'STAKING', image: Building1Hover, buildingClickHandler: clickStakingHouse, link: '/staking'},
    ];

    return (
        <>
            { buildings.map(({ buildingName, image, buildingClickHandler, link }, index) => (
                <React.Fragment key={index}>
                    <BuildingHover
                        number={index + 1}
                        image={image}
                        buildingClickHandler={buildingClickHandler}
                        setBuildingIdToShow={setBuildingIdToShow}
                        name={buildingName}
                        link={link}
                    />
                </React.Fragment>
            )) }
        </>
    );
};
