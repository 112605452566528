import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import { Wombat } from "ual-wombat";

import {
    WAX_CHAIN_ID,
    WAX_RPC_ENDPOINTS_HOST,
    WAX_RPC_ENDPOINTS_PROTOCOL
} from "../constants";

export const waxChain = {
    chainId: WAX_CHAIN_ID,
    rpcEndpoints: [{
        protocol: WAX_RPC_ENDPOINTS_PROTOCOL,
        host: WAX_RPC_ENDPOINTS_HOST,
        port: ''
    }]
};

const wcw = new Wax([waxChain], { appName: 'PixelFarming' });
const wombat = new Wombat([waxChain], { appName: 'PixelFarming' });
const anchor = new Anchor([waxChain], { appName: 'PixelFarming' });

export const waxAuthenticators = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? [anchor]
    : [anchor, wcw, wombat];